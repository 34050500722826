import Homepage from "./pages/Homepage";

import './styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define each route with a path and component */}
        <Route path="/" element={<Homepage />} />
      
      </Routes>
    </Router>
  );
}

export default App;
