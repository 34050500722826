import React from "react";
import propose1 from '../images/DSCF2815.jpg';
import propose2 from '../images/yomi.jpg';
import propose3 from '../images/DSCF2939.jpg';
import propose4 from '../images/DSCF2607.jpg'
import prewed1 from '../images/DSCF4545A.jpg';
import prewed2 from '../images/DSCF4487 (1).jpg';
import prewed3 from '../images/DSCF4159 (2).jpg';
import prewed4 from '../images/DSCF3998.jpg';
import prewed5 from '../images/DSCF4326.jpg';
import court1 from '../images/DSC0382.JPG';
import court3 from '../images/DSC_0368.JPG';
import court2 from '../images/DSC_0365.JPG';
import shape4 from '../images/shape4.png';
import shape8 from '../images/shape8.png';
import shape2 from '../images/shape2.png';
import shape6 from '../images/shape6.png';
import shape1 from '../images/shape1.png';
import shape4a from '../images/shape4a.png';
import trad1 from '../images/LJ3_7881.jpg'
import trad2 from '../images/LJ3_7902.jpg'
import trad3 from '../images/LJ3_7971.jpg'
import trad4 from '../images/LJ3_7936.jpg'
import trad5 from '../images/LJ3_7963.jpg'


export default function Section4() {
    return (
        <div id="gallery" className="section4">
                  <img src={shape4} className="shape4aa" alt="Shape decoration" />
            <img src={shape1} className="shape1a" alt="Shape decoration" />
            <img src={shape8} className="shape8a" alt="Shape decoration" />
            <img src={shape2} className="shape2a" alt="Shape decoration" />
            <img src={shape6} className="shape6a" alt="Shape decoration" />
            <img src={shape4a} className="shape4b" alt="Shape decoration" />
       <div className="gallery1"> <h3 >Gallery</h3></div>
      
   <div className="proposal-cont">
   <div className="proposal">
            <h4>Proposal</h4>
            <div className="prop">
            <img
            src={propose4}
            alt="propose1"
            className="propose1" />
              <img
            src={propose2}
            alt="propose1"
            className="propose2" />
              <img
            src={propose3}
            alt="propose1"
            className="propose3" />
             <img
            src={propose1}
            alt="propose1"
            className="propose4" />
            </div>

        </div>
        <div className="proposal">
            <h4>Court Wedding</h4>
         <div className="court">
         <img
            src={court3}
            alt="propose1"
            className="propose1" />
         <img
            src={court1}
            alt="propose1"
            className="propose1" />
         <img
            src={court2}
            alt="propose1"
            className="propose1" />
      
         </div>
        </div>
        <div className="proposal">
            <h4>Pre-Wedding Shoot</h4>
           <div className="prewed">
           <img
            src={prewed1}
            alt="propose1"
            className="propose1" />
            <img
            src={prewed3}
            alt="propose1"
            className="propose1" />
            <img
            src={prewed2}
            alt="propose1"
            className="propose1" />
            <img
            src={prewed4}
            alt="propose1"
            className="propose1" />
            <img
            src={prewed5}
            alt="propose1"
            className="propose1" />
           </div>
          
        </div>
        <div className="proposal">
            <h4>Traditional Wedding</h4>
           <div className="trad">
           <img
            src={trad1}
            alt="propose1"
            className="propose1" />
           <img
            src={trad2}
            alt="propose1"
            className="propose1" />
           <img
            src={trad3}
            alt="propose1"
            className="propose1" />
           <img
            src={trad4}
            alt="propose1"
            className="propose1" />
           <img
            src={trad5}
            alt="propose1"
            className="propose1" />
           </div>
        </div>
        <div className="proposal">
            <h4>White Wedding</h4>
            <div className="white">
           <p>Coming Soon!</p>
           </div>
        </div>
   </div>
    </div>
    )
}