import React, { useState, useEffect } from 'react';
import { Cross as Hamburger } from 'hamburger-react';
import logo from '../images/logo2.png';


const Navigation = () => {
  const [isOpen, setOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);

  // Function to close the menu when a navigation item is clicked
  const handleNavClick = () => {
    setOpen(false);
  };

  return (
    <div className={`navigation ${visible ? '' : 'hidden'}`}>
      <div>
        <img src={logo} alt='logo' className='logo' />
      </div>
      <div className='hamburger-container'>
        <div className='hamburger-wrapper'>
          <div className={`custom-hamburger ${isOpen ? 'open' : ''}`}>
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
        <ul className={`nav-list ${isOpen ? 'open' : ''}`} onClick={handleNavClick}>
          <li><a href="#ourstory" onClick={handleNavClick}>Our Story</a></li>
          <li><a href="#bridal" onClick={handleNavClick}>Bridal Party</a></li>
          <li><a href="#gallery" onClick={handleNavClick}>Gallery</a></li>
          <li><a href="#ceremony" onClick={handleNavClick}>Ceremony</a></li>
          <li><a href="https://drive.google.com/drive/folders/1dkUZ7CIbipQUOYIZ6zFGRuqO5HHgx2Im?usp=drive_link">Upload Pics</a></li>
         


        </ul>
      </div>
    </div>
  );
};

export default Navigation;
