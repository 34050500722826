import React from "react";
import propose from "../videos/proposal.mp4";
import CountdownTimer from "./CountdownTimer";


export default function Section1() {
  const targetDate = "2024-11-30T23:59:59";

  return (
    <div className="section1">
      <video className="video-bg" autoPlay muted loop>
        <source src={propose} type="video/mp4" />
      </video>
      <div className="overlay"></div>
      <div className="content">
        <h1 className="title">We're getting married!</h1>
        <p className="subtitle">SATURDAY, NOVEMBER 30TH, 2024</p>
      </div>
      <div className="timer-overlay">
        {/* <h1 className="names">Kevwe <span>&</span> Abayomi</h1> */}
        <CountdownTimer targetDate={targetDate} />
      </div>
    </div>
  );
}
