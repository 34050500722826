import React, { useState, useEffect } from 'react';

const DaysWaited = () => {
  const [daysWaited, setDaysWaited] = useState(0);

  useEffect(() => {
    // Define the start date (November 24, 2023)
    const startDate = new Date('2023-11-24');
    // Get the current date
    const currentDate = new Date();
    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate - startDate;
    // Convert the difference in milliseconds to days (1000ms * 60s * 60min * 24h)
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    // Update the state with the number of days
    setDaysWaited(daysDifference);
  }, []);

  return (
    <div className="days-waited">

      <p>They have waited {daysWaited} days and will be joined before their friends and family on the 30th of November 2024, surrounded by loved ones who have supported them throughout their journey. The day will be filled with laughter, joy, and heartfelt moments as they celebrate their love and commitment to each other. As they exchange vows and promises, their hearts will be full, marking the beginning of a wonderful new chapter together.</p>
    </div>
  );
};

export default DaysWaited;
