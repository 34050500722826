import React from "react";
import logo from '../images/logo3.png'

export default function Section6() {
    return (
        <div className="section6">
            <img
            src={logo}
            className="bottom-logo"
            alt="bottom-logo"
            />
          
            <p>Website created by the bride ❤️ &#169; 2024</p>
        </div>
    )
}