import React from "react";
import squad1 from '../images/squad1.jpeg'
import squad2 from '../images/squad2.jpeg'
import squad3 from '../images/squad3.jpeg'
import squad4 from '../images/squad4.jpeg'
import squad5 from '../images/squad5.jpeg'
import squad6 from '../images/squad6.jpeg'
import squad7 from '../images/squad7.jpeg'
import squad8 from '../images/squad8.jpeg'
import squad9 from '../images/squad9.jpeg'
import squad10 from '../images/squad10.jpeg'


export default function Section3() {
    return (
        <div id="bridal" className="section3">
            <div className="bride-text">
                <h4>Bridal Party</h4>
            </div>
            <div className="bridals">
                <div className="bridesmaids">
                    <div className="bridal-squad">
                        <img
                            src={squad4}
                            className="squad4"
                            alt="squad4" />

                    </div>
                    <p className="name">Constance Boi</p>
                    <p className="describe">Chief Bridesmaid</p>
                </div>
               <div className="bridesmaids">
               <div className="bridal-squad" >
                    <img
                        src={squad1}
                        className="squad1"
                        alt="squad1" />
                 
                </div>
                <p className="name">Modupe Adebimpe</p>
                <p className="describe">Bridesmaid</p>
               </div>
             <div className="bridesmaids">
             <div className="bridal-squad">
                    <img
                        src={squad2}
                        className="squad2"
                        alt="squad2" />
                   
                </div>
                <p className="name">Victoria Terry</p>
                <p className="describe"> Bridesmaid</p>
             </div>
             <div className="bridesmaids">
             <div className="bridal-squad">
                    <img
                        src={squad3}
                        className="squad3"
                        alt="squad3" />
                   
                </div>
                <p className="name">Pauline ogbolu</p>
                <p className="describe">Bridesmaid</p>
             </div>
              <div className="bridesmaids">
              <div className="bridal-squad">
                    <img
                        src={squad5}
                        className="squad5"
                        alt="squad5" />
                    
                </div>
                <p className="name">Rachel Bakji</p>
                <p className="describe">Bridesmaid</p>
              </div>
            </div>
            {/* <div className="groom">
                <h4>Groom's Squad</h4>
            </div> */}
            <div className="grooms">
                <div className="bridesmaids">
                    <div className="bridal-squad">
                        <img
                            src={squad6}
                            className="squad6"
                            alt="squad4" />

                    </div>
                    <p className="name">Isreal Olowoyeye</p>
                    <p className="describe">Bestman</p>
                </div>
               <div className="bridesmaids">
               <div className="bridal-squad" >
                    <img
                        src={squad7}
                        className="squad7"
                        alt="squad1" />
                 
                </div>
                <p className="name">Daniel Timi-Ojo</p>
                <p className="describe">Groomsman</p>
               </div>
             <div className="bridesmaids">
             <div className="bridal-squad">
                    <img
                        src={squad8}
                        className="squad8"
                        alt="squad2" />
                   
                </div>
                <p className="name">Harrison Kosinedi</p>
                <p  className="describe">Groomsman</p>
             </div>
             <div className="bridesmaids">
             <div className="bridal-squad">
                    <img
                        src={squad9}
                        className="squad9"
                        alt="squad3" />
                   
                </div>
                <p className="name">Osinachi Chibuzor</p>
                <p className="describe">Groomsman</p>
             </div>
              <div className="bridesmaids">
              <div className="bridal-squad">
                    <img
                        src={squad10}
                        className="squad10"
                        alt="squad5" />
                    
                </div>
                <p className="name">Tosin Otegbade</p>
                <p className="describe">Groomsman</p>
              </div>
            </div>
        </div>
    );
}
