import React from "react";
import hug from '../images/DSCF4600A.jpg';
import kiss from '../images/DSCF4680.jpg';
import soap from '../images/DSCF482911.jpg';
import propose from '../images/DSCF2607.jpg';
import shape4 from '../images/shape4.png';
import shape8 from '../images/shape8.png';
import shape2 from '../images/shape2.png';
import shape6 from '../images/shape6.png';
import shape1 from '../images/shape1.png';
import shape4a from '../images/shape4a.png';
import DaysWaited from "../components/DaysWaited"; // Import the DaysWaited component

export default function Section2() {
    return (
        <div id="ourstory" className="section2">
            
            <img src={shape4} className="shape4" alt="Shape decoration" />
            <img src={shape1} className="shape1" alt="Shape decoration" />
            <img src={shape8} className="shape8" alt="Shape decoration" />
            <img src={shape2} className="shape2" alt="Shape decoration" />
            <img src={shape6} className="shape6" alt="Shape decoration" />
            <img src={shape4a} className="shape4a" alt="Shape decoration" />
          <div  className="story">  <h3>Our Story...</h3></div>
           
            <div className="sec2">
                <div className="sec2a">
                    <div className="story-text">
                        <h4>How we met</h4>
                        <p>We met through a mutual friend because Yomi wanted to learn how to code—but instead, he ended up falling in love. Funny enough, it’s been two years, and he still hasn’t learned to code!</p>
                    </div>
                    <div>
                        <img src={kiss} alt="hug" className="hug" />
                    </div>
                    <div className="corner-lines top-left"></div>
                    <div className="corner-lines bottom-right"></div>
                </div>

                <div className="sec2b">
                    <div>
                        <img src={soap} alt="hug" className="soap" />
                    </div>
                    <div className="story-text">
                        <h4>Our first date</h4>
                        <p>Our first date was simple but unforgettable. We met up at Cold Stone Creamery—Yomi picked his usual, cookies and cream, while I couldn’t resist going all out with toppings. After our ice cream, we took a walk, just wandering and talking, with the city lights glowing around us. There was nothing over-the-top, just a real, easy connection. By the end, we both knew we’d found something special.</p>
                    </div>
                    <div className="corner-lines top-left"></div>
                    <div className="corner-lines bottom-right"></div>
                </div>
                
                <div className="sec2a">
                    <div className="story-text">
                        <h4>The Proposal</h4>
                        <p>Yomi knew how much Kevwe cherished Korea and its dramas, so he wanted his proposal to be truly memorable. Secretly, he spoke to her mom to get her blessing. Then, he picked her up for a surprise 'date' at her favorite Korean restaurant, where he proposed. Overwhelmed with joy, Kevwe happily said yes!</p>
                    </div>
                    <div>
                        <img src={propose} alt="hug" className="soap" />
                    </div>
                    <div className="corner-lines top-left"></div>
                    <div className="corner-lines bottom-right"></div>
                </div>

                <div className="sec2b">
                    <div>
                        <img src={hug} alt="hug" className="hug" />
                    </div>
                    <div className="story-text">
                        <h4>Together Forever</h4>
                        <DaysWaited />
                    </div>
                    <div></div>
                    <div className="corner-lines top-left"></div>
                    <div className="corner-lines bottom-right"></div>
                </div>
            </div>
        </div>
    );
}

