import React from "react";


export default function Section5() {
    return (
        <div id="ceremony" className="section5">
            <div className="sec5">
                <div className="card1">
                    <h3>The Ceremony</h3>
                    <p>Saturday, November 30th, 2024</p>
                    <p>10am</p>
                    <p>The Redeemed Evangelical Mission</p>
                    <p>(Trem Wealthy Place)</p>
                    <p>'A' Street, Shagari Estate Phase 1, Off Kokumo Road, Iyana Ipaja, Lagos</p>
                    <a href="https://maps.google.com/?cid=14512844797178784089">See Location</a>

                   
                </div>
                <div className="card2">
                    <h3>The Reception</h3>
                    <p>
                        NECA House Events Centre</p>
                    <p>NECA HOUSE BUILDING, Hakeem Balogun St, Alausa, Ikeja</p>
                    <a href="https://maps.google.com/?cid=2848930393299131206">See Location</a>
                </div>
            </div>
        </div>
    )
}